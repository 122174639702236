type Callbacks = {
  onCancel?: () => void
  onFailure?: (data: any) => void
  onSuccess?: () => void
}

export default function useIntegrationConnect() {
  const { analytics } = useAnalytics()
  const apiPath = useApiPath()
  const receivedMessage = ref()

  return async function connect(service: OAuthService, callbacks?: Callbacks) {
    receivedMessage.value = false

    const data = await $post<{ authorizationUrl: string }>(
      apiPath('initializeOAuth', { service }) as string,
    )
    const promise = new Promise((resolve, reject) => {
      openPopup({
        url: data.authorizationUrl,
        onPopupClosed: () => {
          analytics.value?.track('oauth:connection:cancel', service as any)
          if (!receivedMessage.value) reject(new Error('cancelled'))
          removeListener()
        },
      })

      async function onMessage(e: any) {
        receivedMessage.value = true
        if (e.data?.code && e.data?.state) {
          analytics.value?.track('oauth:connection:success', service as any)
          try {
            const response = await $post(
              apiPath('finalizeOAuth', { service }) as string,
              e.data,
            )
            resolve(response)
          } catch (e) {
            reject(e)
          }
        } else {
          analytics.value?.track('oauth:connection:failure', service as any)
          reject(e)
        }
      }
      function removeListener() {
        window.removeEventListener('message', onMessage)
      }

      window.addEventListener('message', onMessage)
    })

    return promise
  }
}
