<template>
  <AtroButton
    type="blank"
    :class="['!px-3 !py-1.5', gradientBackground]"
    :disabled="hasBeenExecuted"
    @click="onClick"
  >
    <AtroSpan semibold class="text-white" size="xs" :text="label" />
  </AtroButton>
</template>

<script setup lang="ts">
export interface Props {
  label: string
  value: string
}

const tailwindColors = [
  { start: 'from-blue-500', end: 'to-blue-600' },
  { start: 'from-purple-500', end: 'to-purple-600' },
  { start: 'from-indigo-500', end: 'to-indigo-600' },
  { start: 'from-teal-500', end: 'to-teal-600' },
  { start: 'from-cyan-500', end: 'to-cyan-600' },
]

const { label, value } = defineProps<Props>()
const emit = defineEmits<{
  execute: [prompt: string]
}>()

const hasBeenExecuted = ref(false)

const gradientBackground = computed(() => {
  // Use the label to generate a consistent gradient for each unique label
  const hash = label.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)

  const index = Math.abs(hash) % tailwindColors.length
  const selectedGradient = tailwindColors[index]

  return [
    'bg-gradient-to-r',
    selectedGradient.start,
    selectedGradient.end,
    'text-white',
    'p-4',
    'rounded-lg',
  ]
})

function onClick() {
  if (!hasBeenExecuted.value) emit('execute', value.trim())
  hasBeenExecuted.value = true
}
</script>
