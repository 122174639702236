import { createInput, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import icons from '@atro/atro-icons'
import formkitConfig from '@atro/formkit-config'
import Datepicker from './components/formkit/Datepicker.vue'
import InputCopy from './components/formkit/InputCopy.vue'
import Markdown from './components/formkit/Markdown.vue'
import Repeated from './components/formkit/Repeated.vue'
import Switch from './components/formkit/Switch.vue'
import TagCloud from './components/formkit/TagCloud.vue'
import Upload from './components/formkit/Upload.vue'

const textConfig = {
  label: 'mb-2 line-clamp-1',
  inner: 'rounded-xl border-2 border-atro-warm-gray bg-white',
  input:
    'w-full border-none py-2.5 px-3 sm:px-4 bg-transparent placeholder:!text-atro-gray disabled:!text-atro-gray/45 read-only:focus:!border-atro-warm-gray read-only:cursor-default',
  outer:
    'w-full group-[.form-input-width-fixed]/form:!min-w-48 group-[.form-input-width-fixed]/form:!max-w-64  group-[.form-input-width-full]/form:max-w-full',
}

// FOR LOCAL FORMKIT THEME DEBUGGING/IMPROVEMENTS
const theme = {
  global: {
    actions: 'mt-auto col-span-full grid',
    form: 'w-full group/form',
    fieldset: '',
    help: 'text-xs text-atro-gray',
    inner:
      'flex items-center relative w-full has-[:focus]:border-atro-focus-light formkit-invalid:border-atro-red transition-colors',
    input: 'enabled:focus:border-none focus:!ring-0',
    label: 'font-semibold text-sm text-atro-gray inline-block',
    legend: '',
    loaderIcon: '',
    message: 'text-xs text-atro-red',
    messages: 'space-y-1 text-left list-none pl-0 mt-1',
    outer:
      'group-[.form-align-center]/form:place-self-center group-[.form-align-end]/form:place-self-end group-[.form-align-start]/form:place-self-start group-[.form-width-xs]/form:max-w-[240px] group-[.form-width-sm]/form:max-w-xs group-[.form-width-md]/form:max-w-sm group-[.form-width-lg]/form:max-w-md group-[.form-width-full]/form:w-full',
    prefixIcon: 'flex size-5 ml-3 -mr-1',
    suffixIcon: 'flex size-5 mr-3',
    wrapper: 'text-left',
  },

  copy: {
    ...textConfig,
    inner: '',
    outer: 'w-full',
  },
  'family:text': textConfig,
  datepicker: textConfig,
  mask: textConfig,
  textarea: textConfig,

  checkbox: {
    input:
      '!w-6 !h-6 border-2 border-primary rounded-lg cursor-pointer bg-white hover:border-primary-75 checked:text-atro-green disabled:checked:bg-atro-green checked:border-atro-green hover:enabled:checked:bg-atro-green-50 hover:enabled:checked:border-atro-green disabled:opacity-25',
    label: 'ml-2 cursor-pointer',
    options: 'flex flex-col flex-grow space-y-2',
    wrapper: 'flex flex-row flex-grow items-center',
  },
  radio: {
    inner: '!inline-block !w-auto mr-2',
    label: 'inline-block !font-medium',
    legend: 'mb-6',
    options: 'space-y-2',
  },
  repeated: {
    inner:
      'flex-col group-[.form-gap-lg]/form:gap-4 group-[.form-gap-md]/form:gap-3 group-[.form-gap-sm]/form:gap-2 group-[.form-gap-lg]/form:sm:gap-8 group-[.form-gap-md]/form:sm:gap-6 group-[.form-gap-sm]/form:sm:gap-4',
    label: 'hidden',
    outer: 'w-full',
  },
  search: {
    ...textConfig,
    input: 'w-full py-2 px-4 rounded-xl border-2 border-atro-warm-gray',
  },
  select: {
    ...textConfig,
    input: `${textConfig.input} !pr-8`,
  },
  switch: {
    help: 'hidden',
    label: 'mb-2 inline-block',
    inner: 'text-atro-slate-purple',
    prefixIcon: '!ml-0 mr-2',
  },
  upload: {
    label: 'mb-4',
  },
}

const config = defaultConfig({
  ...formkitConfig,
  icons,
  // config: {
  //   classes: generateClasses(theme),
  // },
  inputs: {
    copy: createInput(InputCopy),
    datepicker: createInput(Datepicker),
    markdown: createInput(Markdown),
    mask: maskInput,
    repeated: createInput(Repeated),
    switch: createInput(Switch),
    tagCloud: createInput(TagCloud),
    upload: createInput(Upload),
  },
})
export default config
