<template>
  <AtroDropzone
    :asset-name
    :asset-type
    :multiple
    v-model="modelValue"
    @on-files="onFiles"
    @reset="reset"
  >
    <template #restrictions v-if="restrictions?.length">
      <AtroContent
        class="mt-2 text-atro-gray"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroSpan
          v-for="restriction in restrictions"
          class="leading-4"
          size="sm"
          :key="restriction"
          :text="restriction"
        />
      </AtroContent>
    </template>

    <template #body v-if="modelValue && assetType === 'image'">
      <NuxtImg :src="modelValue" class="max-w-48" />
    </template>

    <template #loading v-if="isUploading">
      <AtroLoading class="absolute inset-0 z-1" />
    </template>

    <template #status v-if="uploadStatusText">
      <AtroSpan
        size="sm"
        :class="{
          'text-atro-red': isUploadFailed,
          'text-atro-green': isUploadSuccessful,
        }"
        :text="uploadStatusText"
      />
    </template>
  </AtroDropzone>
</template>

<script setup lang="ts">
import mimeTypes from 'mime-db'
import { kebabKeys } from 'js-convert-case'
import type { DropZoneProps } from '@atro/components'

type PresignedResponse = {
  fields: Record<string, string>
  keyBase: string
  url: string
}

export interface Props {
  uploadContext: UploadContext

  assetName?: string
  assetType?: DropZoneProps['assetType']
  modelValue?: string | string[]
  multiple?: boolean
  restrictions?: string[]
}

const { multiple, uploadContext, assetType = 'image' } = defineProps<Props>()
const modelValue = defineModel<Props['modelValue']>()

const { org } = useCurrentOrg()

const uploadStatus = ref<'failed' | 'idle' | 'success' | 'uploading'>()
const uploadErrors = ref()

const isUploading = computed(() => uploadStatus.value === 'uploading')
const isUploadFailed = computed(() => uploadStatus.value === 'failed')
const isUploadSuccessful = computed(() => uploadStatus.value === 'success')
const uploadStatusText = computed(() => {
  switch (uploadStatus.value) {
    case 'failed':
      return 'Upload failed! Please try again'
    case 'success':
      return 'Upload successful!'
    default:
      return ''
  }
})

async function onFiles(files: File[] | FileList) {
  if (files?.length) {
    uploadStatus.value = 'uploading'
    uploadErrors.value = []
    for (const file of files) {
      try {
        const { fields, keyBase, url } = await $fetch<PresignedResponse>(
          getApiPath('presignedS3', {
            context: uploadContext,
            orgId: org.value?.id,
          }),
        )
        const formData = new FormData()
        Object.entries(kebabKeys(fields)).forEach(([key, val]) =>
          formData.append(key, val),
        )
        const extension = mimeTypes[file.type]?.extensions?.[0]
        const key = extension ? `${keyBase}.${extension}` : keyBase
        formData.set('key', key)
        formData.append('Content-Type', file.type)
        formData.append('file', file)
        const response = await $fetch.raw(url, {
          body: formData,
          method: 'POST',
          mode: 'cors',
        })
        const location = response.headers.get('location') as string
        if (multiple && Array.isArray(modelValue.value)) {
          modelValue.value?.push(location)
        } else {
          modelValue.value = location
        }
      } catch (e) {
        // TODO: add s3 XML error parsing
        // const errorData = convertXml.xml2js(e.data)
        console.log('e.data', e.data)
        uploadStatus.value = 'failed'
        uploadErrors.value.push(e)
      }
    }
  }
  if (uploadStatus.value === 'uploading') {
    uploadStatus.value = 'success'
  }
}

function reset() {
  uploadStatus.value = 'idle'
  modelValue.value = undefined
}
</script>
