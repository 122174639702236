<template>
  <div class="w-full">
    <AtroGridTable gap="sm" :data="vendors" :columns="columnsConfig">
      <template #default="{ columnProps, items }">
        <AssessmentVendorTableRow
          v-for="vendor in items"
          v-bind="columnProps"
          :vendor
          :row-actions
          :key="vendor.id"
          @action="onAction"
        />
      </template>
    </AtroGridTable>
  </div>
</template>

<script setup lang="ts">
interface Vendor {
  id: string
  name: string
  contact: string
  email: string
  status: 'in_progress' | 'overdue' | 'complete'
  score: number
  daysOverdue?: number
}

interface Props {
  vendors: Vendor[]
  rowActions?: MenuItem[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()

const columnsConfig = computed<TableColumnConfig[]>(() => {
  const columns: TableColumnConfig[] = [
    {
      heading: {
        align: 'start',
        text: 'Vendor',
      },
      minColumnWidth: 120,
      sortBy: 'name',
    },
    {
      heading: {
        align: 'start',
        text: 'Contact',
      },
      minColumnWidth: 120,
      sortBy: 'contact',
    },
    {
      heading: {
        align: 'start',
        text: 'Email',
      },
      minColumnWidth: 180,
      sortBy: 'email',
    },
    {
      heading: {
        text: 'Status',
      },
      fixedWidth: true,
      minColumnWidth: 140,
      sortBy: 'status',
    },
    {
      heading: {
        text: 'Score',
      },
      fixedWidth: true,
      minColumnWidth: 110,
      sortBy: 'score',
    },
  ]
  if (props.rowActions) {
    columns.push({
      actionColumn: true,
      actionColumnShrink: true,
    })
  }
  return columns
})

function onAction(action: string, vendor: Vendor) {
  emit('action', {
    type: 'send_action',
    data: { actionSlug: action, body: { id: vendor.id } },
  })
}
</script>
