export const pagePrePromptMap = {
  '': '',
}

export const dashboardFollowupPrompts = [
  {
    label: 'High Priority Risks',
    value: `
      {
        "system_context": "You are analyzing the dashboard to identify and explain the most critical risks and alerts that require immediate attention.",
        
        "instruction": "Continue the conversation by highlighting the most pressing risks and alerts from the dashboard data.",
        
        "required_elements": {
          "format": {
            "transition": "Let's look at your most important alerts:",
            "risks": [
              "• [Critical/High Risk]: [Brief description] - [Impact]",
              "• [Next Risk if applicable]: [Brief description] - [Impact]"
            ]
          },
          "length": "2-3 key risks/alerts maximum"
        },

        "example_output": {
          "with_critical": "Let's look at your most important alerts:\n\n• CRITICAL: Cloud storage buckets have public access enabled - Creating immediate data exposure risk\n• HIGH: 5 admin accounts lack MFA - Potential account compromise vulnerability",
          
          "moderate_risks": "Let's look at your most important alerts:\n\n• HIGH: EDR deployment gap on 15 marketing laptops - Systems vulnerable to modern malware attacks\n• MEDIUM: Outdated SSL certificates on 3 domains - Potential security warnings for users"
        },

        "output_rules": [
          "Start directly with the risks - no greeting needed",
          "Use bullet points for each risk",
          "Sort by severity and potential impact",
          "Focus on actionable items only",
          "Use clear, non-technical language"
        ]
      }
    `,
  },
  {
    label: 'Critical Actions',
    value: `
      {
        "system_context": "You are providing clear, actionable next steps based on the dashboard state, focusing on highest-impact activities.",
        "instruction": "Extend the conversation with specific recommendations for improving security posture or resolving issues.",
        "required_elements": {
          "format": {
            "transition": "Here are your recommended next steps:",
            "actions": [
              "• [Priority Level] [Action]: [Brief benefit]",
              "  ↳ How: [Quick implementation guide]"
            ]
          },
          "length": "2-3 specific recommendations maximum"
        },
        "example_output": {
          "urgent_actions": "Here are your recommended next steps:\n\n• URGENT: Add incident response team to platform\n  ↳ How: Click 'Invite Users' in settings (5-minute setup)\n\n• IMPORTANT: Enable cloud scanning module\n  ↳ How: Follow the quick setup guide in Cloud Security tab",
          "improvement_focused": "Here are your recommended next steps:\n\n• RECOMMENDED: Schedule automated policy review\n  ↳ How: Use the Policy Scheduler in Compliance tab\n\n• OPTIONAL: Enable breach monitoring\n  ↳ How: Available free with your current plan in Security Settings"
        },
        "output_rules": [
          "Start with a natural transition",
          "Use clear bullet hierarchy for steps and implementation",
          "Prioritize mandatory or high-impact actions",
          "Make steps feel achievable and clear",
          "Include specific platform locations for each action"
        ]
      }
    `,
  },
  {
    label: 'Advanced Insights',
    value: `
      {
        "system_context": "You are performing advanced analysis of the dashboard data to uncover non-obvious insights, patterns, or potential security gaps.",
        "instruction": "Conclude the analysis by identifying a meaningful pattern or hidden risk that adds unique value.",
        "required_elements": {
          "format": {
            "transition": "Here's an interesting insight from your data:",
            "insight": [
              "• [Main observation]",
              "• [Supporting evidence]",
              "• [Why this matters]"
            ]
          },
          "length": "One clear insight explained in 3 bullet points"
        },
        "example_output": {
          "pattern_insight": "Here's an interesting insight from your data:\n\n• Security alerts consistently spike every Monday around 9 AM\n• This correlates with remote worker VPN connections - 60% are false positives\n• Optimizing VPN configuration could significantly reduce alert noise",
          "configuration_insight": "Here's an interesting insight from your data:\n\n• Your cloud security rules don't account for serverless functions\n• 40% of your new cloud assets are serverless deployments\n• This creates a growing blind spot in your security monitoring"
        },
        "output_rules": [
          "Start with a natural transition",
          "Break insight into clear bullet points",
          "Focus on non-obvious insights that add value",
          "Connect multiple data points to form conclusions",
          "Ensure insights are actionable",
          "Base insights on concrete evidence"
        ]
      }
  `,
  },
]

export default pagePrePromptMap
