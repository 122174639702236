<template>
  <AtroContent class="h-full w-full flex-1" direction="col" wrap="nowrap">
    <Transition appear name="fade" :css="animated">
      <slot name="prefix" :current-page :on-action :pending :valid />
    </Transition>

    <AtroContent
      class="relative h-full w-full flex-1"
      direction="col"
      wrap="nowrap"
    >
      <Transition name="fade">
        <slot
          v-if="currentPage && !isAnimating"
          name="pageController"
          :animated
          :data
          :deps
          :on-action
          :page="currentPage"
          :page-type
          :pending
          :valid
        >
          <PageController
            :animated
            :data
            :deps
            :page="currentPage"
            :page-type
            @action="onAction"
          />
        </slot>
      </Transition>
      <AtroContent
        v-if="!currentPage"
        class="h-full w-full flex-1"
        items="center"
        justify="center"
      >
        <AtroHeading text="Missing page!" />
      </AtroContent>
    </AtroContent>

    <Transition appear name="fade" :css="animated">
      <slot
        name="postfix"
        :current-page
        :current-page-index
        :next-page
        :on-action
        :pending
        :prev-page
        :valid
      />
    </Transition>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  pages: Page[]

  actionsConfig?: ActionsConfig
  animated?: boolean
  data?: Record<string, any>
  deps?: Record<string, any>
  isAnimating?: boolean
  pageType?: 'main' | 'modal'
}

const {
  deps,
  data,
  pages,
  animated = true,
  actionsConfig = {},
  pageType = 'main',
} = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction, page: Page]
  pageChange: [page: Page]
}>()

const router = useRouter()
const { pending, valid, reset: resetPageState } = usePageState()

const onAction = useBRActions({
  pages,
  ...actionsConfig,
  deps: computed(() => deps),
  state: computed(() => data || {}),
  overrideActions: {
    go_to_page: (payload) => goToPageBySlug(payload),
    go_to_page_and_submit: async (payload) => {
      await onAction(
        {
          type: 'save_page_data',
        },
        currentPage.value,
      )
      goToPageBySlug(payload)
    },
    next_page: nextPage,
    prev_page: prevPage,
    ...(actionsConfig.overrideActions ? actionsConfig.overrideActions : {}),
  },
})

const initialPageView = ref(true)
const currentPageIndex = ref(
  router.currentRoute.value.query.page
    ? Math.max(
        pages.findIndex((p) => p.slug === router.currentRoute.value.query.page),
        0,
      )
    : 0,
)

const currentPage = computed(() => pages[currentPageIndex.value])
const hasNextPage = computed(() => currentPageIndex.value + 1 < pages.length)
const hasPreviousPage = computed(() => currentPageIndex.value > 0)

function goToPageBySlug(slug: string) {
  const pageIndex = pages.findIndex((page) => page.slug === slug)
  if (pageIndex >= 0) currentPageIndex.value = pageIndex
  else {
    console.error(
      'MISSING PAGE SLUG:',
      slug,
      'AVAILABLE SLUGS:',
      pages.map((page) => page.slug),
    )
  }
}

function nextPage() {
  if (hasNextPage.value) {
    currentPageIndex.value += 1
  }
}

function prevPage() {
  if (hasPreviousPage.value) {
    currentPageIndex.value -= 1
  }
}

function onPageChange(
  currentPageIndex: number,
  prevPageIndex: number | undefined,
) {
  initialPageView.value = prevPageIndex === undefined
  resetPageState()
  if (currentPage.value?.pageViewAction)
    onAction(
      {
        type: 'send_action',
        data: { actionSlug: currentPage.value.pageViewAction },
      },
      currentPage.value,
    )
  emit('pageChange', currentPage.value)
  console.log('CURRENT PAGE', currentPage.value, 'data', data)
}

onMounted(() => {
  if (router.currentRoute.value.query.action) {
    onAction(
      {
        type: router.currentRoute.value.query.action as FlowActionType,
        data: router.currentRoute.value.query.payload,
      },
      currentPage.value,
    )
  }
})
watch(currentPageIndex, onPageChange, { immediate: true })

defineExpose({
  currentPage,
  currentPageIndex,
  hasNextPage,
  hasPreviousPage,
  goToPageBySlug,
  nextPage,
  onAction,
  prevPage,
})
</script>
