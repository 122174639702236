import revive_payload_client_VB6Dfprw9c from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6znHopjh40 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tGl7sKaytK from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_w2sVOJLv5H from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.4_magicast@0.3.5_rollup@4.27.4_vite@5.4.11_@types+node@22.10.1_terser@5._j62zmrjna6qid5i5fymuegkbey/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_LJpo3t9iOT from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mgLkRqgFSe from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gDmlzOFhXm from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_O1JoBh4m6x from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_4SY2Xgzy9U from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.1__ioredis@5._rebf4botzmnp7edj2tulgcjle4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tlnn1tOKzf from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_reac_fatni5k7nqbh2mihryxtamk5me/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import nuxt_plugin_e4cFhdg95p from "/vercel/path0/node_modules/.pnpm/vue-recaptcha@3.0.0-alpha.6_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.7.2_/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import apex_charts_client_nU3LNFyvBu from "/vercel/path0/plugins/apex-charts.client.ts";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import auto_expand_client_XgXhwPISRQ from "/vercel/path0/plugins/auto-expand.client.ts";
import chatwoot_crLW9YLG1U from "/vercel/path0/plugins/chatwoot.ts";
import click_to_copy_IKq4Bft4DW from "/vercel/path0/plugins/click-to-copy.ts";
import electron_device_rFvu8uW7qu from "/vercel/path0/plugins/electron-device.ts";
import floating_vue_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import mobile_OriQq9jmDY from "/vercel/path0/plugins/mobile.ts";
import scroller_iZCcO5r4hO from "/vercel/path0/plugins/scroller.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import sonner_KD25tq4EBi from "/vercel/path0/plugins/sonner.ts";
import trackers_FpmZMuzECg from "/vercel/path0/plugins/trackers.ts";
export default [
  revive_payload_client_VB6Dfprw9c,
  unhead_6znHopjh40,
  router_tGl7sKaytK,
  _0_siteConfig_w2sVOJLv5H,
  payload_client_LJpo3t9iOT,
  navigation_repaint_client_mgLkRqgFSe,
  check_outdated_build_client_gDmlzOFhXm,
  chunk_reload_client_O1JoBh4m6x,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4SY2Xgzy9U,
  plugin_tlnn1tOKzf,
  plugin_wy0B721ODc,
  formkitPlugin_pZqjah0RUG,
  nuxt_plugin_e4cFhdg95p,
  apex_charts_client_nU3LNFyvBu,
  auto_animate_uTpJuTGsMC,
  auto_expand_client_XgXhwPISRQ,
  chatwoot_crLW9YLG1U,
  click_to_copy_IKq4Bft4DW,
  electron_device_rFvu8uW7qu,
  floating_vue_BiOD74u9sH,
  mobile_OriQq9jmDY,
  scroller_iZCcO5r4hO,
  sentry_3AyO8nEfhE,
  sonner_KD25tq4EBi,
  trackers_FpmZMuzECg
]