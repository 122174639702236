<template>
  <AtroContent class="w-full" direction="col" items="center" justify="center">
    <AtroContent
      class="w-full gap-2"
      direction="col"
      items="center"
      justify="center"
    >
      <AtroIcon name="assessment-security-coverage" />
      <AtroHeading
        semibold
        class="text-atro-slate-purple"
        size="lg"
        text="Security Coverage Map"
      />
      <AtroParagraph
        class="text-atro-gray"
        size="sm"
        text="Based on your assessment here's the security measure you have in place vs. what we recommend"
      />
    </AtroContent>

    <AtroContent
      v-if="hasSecurityMeasuresNeeded"
      class="mt-10 gap-10"
      justify="center"
    >
      <AtroContent class="gap-2" items="center">
        <AtroIcon class="size-4 text-atro-red" name="circle-xmark" />
        <AtroSpan semibold class="text-atro-red" size="lg">
          {{ securityMeasuresNeeded.length }} Tools missing
        </AtroSpan>
      </AtroContent>

      <AtroContent class="gap-2" items="center">
        <AtroIcon class="size-4 text-atro-green" name="circle-xmark" />
        <AtroSpan semibold class="text-atro-green" size="lg">
          {{ securityMeasures.length - securityMeasuresNeeded.length }} Tools in
          place
        </AtroSpan>
      </AtroContent>
    </AtroContent>

    <AtroContent class="mt-6 gap-2" items="center" justify="center">
      <AtroContent
        v-for="securityMeasure in securityMeasures"
        :class="[
          'relative h-[120px] w-[136px] rounded-3xl border bg-white p-2 text-center',
          {
            'border-atro-red': securityMeasure.status === 'warning',
            'border-atro-green': securityMeasure.status === 'ok',
          },
        ]"
        justify="center"
        items="center"
        direction="col"
        wrap="nowrap"
        :key="securityMeasure.title"
      >
        <AtroIcon class="size-8" :name="securityMeasure.icon" />
        <AtroSpan
          class="mt-2 !leading-4 text-atro-slate-purple"
          size="xs"
          :text="securityMeasure.title"
        />
        <AtroIcon
          :class="[
            'absolute right-2 top-2 size-4',
            {
              'text-atro-green': securityMeasure.status === 'ok',
              'text-atro-red': securityMeasure.status === 'warning',
            },
          ]"
          :name="
            securityMeasure.status === 'ok' ? 'circle-check' : 'circle-xmark'
          "
        />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  securityMeasures: {
    icon: IconName
    status: 'ok' | 'warning'
    title: string
  }[]
}

const { securityMeasures } = defineProps<Props>()

const securityMeasuresNeeded = computed(() =>
  securityMeasures.filter((securityMeasure) => securityMeasure.status !== 'ok'),
)
const hasSecurityMeasuresNeeded = computed(
  () => securityMeasuresNeeded.value.length > 0,
)
</script>
