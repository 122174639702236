<template>
  <AtroGridTableRow>
    <!-- VENDOR NAME -->
    <AtroGridTableCell>
      <AtroSpan semibold size="sm" :text="vendor.name" />
    </AtroGridTableCell>

    <!-- CONTACT -->
    <AtroGridTableCell>
      <AtroSpan class="text-atro-gray" size="xs" :text="vendor.contact" />
    </AtroGridTableCell>

    <!-- EMAIL -->
    <AtroGridTableCell click-to-copy>
      <AtroSpan class="text-atro-gray" size="xs" :text="vendor.email" />
    </AtroGridTableCell>

    <!-- STATUS -->
    <AtroGridTableCell justify="center">
      <AtroBadge size="sm" :label="statusText" :type="statusBadgeType" />
    </AtroGridTableCell>

    <!-- SCORE -->
    <AtroGridTableCell justify="center">
      <ScoreGridTableCell size="sm" :score="vendor.score" />
    </AtroGridTableCell>

    <!-- ACTIONS -->
    <AtroGridTableActionCell v-if="rowActions">
      <OverflowMenu size="sm" :items="rowActions" @action="onMenuAction" />
    </AtroGridTableActionCell>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
export interface Vendor {
  id: string
  name: string
  contact: string
  email: string
  status: 'in_progress' | 'overdue' | 'complete'
  score: number
  daysOverdue?: number
}

interface Props {
  vendor: Vendor
  rowActions?: MenuItem[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: string, vendor: Vendor]
}>()

const statusText = computed(() => {
  switch (props.vendor.status) {
    case 'in_progress':
      return 'In progress'
    case 'overdue':
      return `${props.vendor.daysOverdue} Days overdue`
    case 'complete':
      return 'Complete'
    default:
      return ''
  }
})

const statusBadgeType = computed(() => {
  switch (props.vendor.status) {
    case 'in_progress':
      return 'neutral'
    case 'overdue':
      return 'incorrect'
    case 'complete':
      return 'correct'
    default:
      return 'neutral'
  }
})

function onMenuAction(action: string) {
  emit('action', action, props.vendor)
}
</script>
