export default function useCopyText() {
  const textToCopy = ref('')
  const permissionWrite = usePermission('clipboard-write')
  const { copy } = useClipboard({ source: textToCopy })
  const toast = useToast()

  function copyText(text?: string, afterCopyToast?: string) {
    if (text) textToCopy.value = text
    if (permissionWrite) {
      copy()
      if (afterCopyToast) toast(afterCopyToast)
    } else {
      toast.error("We don't have permission to copy to your device's clipboard")
    }
  }

  return copyText
}
