<template>
  <AtroContent class="w-full" direction="col" items="center">
    <!-- Header -->
    <AtroHeading
      centered
      semibold
      class="text-atro-slate-purple"
      size="xl"
      text="Your Security Posture"
    />

    <AtroContent class="mt-10 w-full gap-20" items="center" wrap="nowrap">
      <!-- Gauge Section -->
      <AtroContent
        shrink
        class="w-full"
        direction="col"
        items="center"
        justify="center"
      >
        <PostureGauge :score class="mb-16" size="lg" />

        <AtroParagraph
          centered
          class="mt-4 !leading-snug text-atro-gray"
          size="xs"
          text="This represents your overall coverage and health of security measures you have in place"
        />

        <AtroButton
          v-if="viewAnswersAction"
          class="mt-2"
          text="View Answers"
          type="flat"
          size="sm"
          @click="emit('action', viewAnswersAction)"
        />
      </AtroContent>

      <!-- Security Ingredients Section -->
      <AtroContent v-if="false" shrink direction="col">
        <AtroHeading
          class="mb-6 text-atro-gray"
          size="xs"
          text="Security posture ingredients"
        />

        <!-- Tool Coverage -->
        <AtroContent
          shrink
          class="mb-6 items-start justify-between gap-4"
          wrap="nowrap"
        >
          <AtroContent shrink class="gap-2" direction="col">
            <AtroContent class="gap-2">
              <AtroIconWrap
                circle
                class="!p-1.5"
                name="shield"
                size="xs"
                type="warning"
              />
              <AtroHeading
                semibold
                class="text-atro-slate-purple"
                size="sm"
                text="Tool Coverage"
              />
            </AtroContent>
            <AtroParagraph
              class="text-atro-gray"
              size="sm"
              text="Amount of coverage in place. Get to 100% coverage to secure your business"
            />
          </AtroContent>
          <AtroCard
            bordered
            class="border-atro-red/30 p-3 text-atro-red"
            rounding="md"
            size="auto"
            type="flat"
          >
            <AtroHeading semibold size="xl" :text="`${toolCoverage}%`" />
          </AtroCard>
        </AtroContent>

        <!-- Security Tool Risk -->
        <AtroContent
          shrink
          class="mb-6 items-start justify-between gap-4"
          wrap="nowrap"
        >
          <AtroContent shrink class="gap-2" direction="col">
            <AtroContent class="gap-2">
              <AtroIconWrap
                circle
                class="!p-1.5"
                name="wave-pulse"
                size="xs"
                type="warning"
              />
              <AtroHeading
                semibold
                class="text-atro-slate-purple"
                size="sm"
                text="Security Tool Risk"
              />
            </AtroContent>
            <AtroParagraph
              class="text-atro-gray"
              size="sm"
              text="Work in Atro to document existing solutions and implement new ones to lower your risk"
            />
          </AtroContent>
          <AtroCard
            bordered
            class="border-atro-red/30 p-3 text-atro-red"
            rounding="md"
            size="auto"
            type="flat"
          >
            <AtroHeading semibold size="xl" text="High" />
          </AtroCard>
        </AtroContent>

        <!-- Financial Loss -->
        <AtroCard
          class="w-full items-center justify-center gap-6 border-2 border-atro-red/30 bg-atro-red/5 p-4"
          direction="row"
          rounding="xl"
          type="flat"
        >
          <AtroHeading
            semibold
            class="text-atro-red"
            size="lg"
            :text="financialLossPotential"
          />
          <AtroSpan
            semibold
            class="text-atro-slate-purple"
            size="lg"
            text="Financial Loss potential"
          />
        </AtroCard>
      </AtroContent>
    </AtroContent>

    <AtroDivider size="2xl" thickness="sm" />

    <!-- Actions Section -->
    <AtroCard
      class="w-full text-center"
      color="gray2"
      direction="col"
      items="center"
    >
      <AtroHeading semibold class="mb-8 text-atro-slate-purple">
        How to increase your security posture from
        <span :class="getScoreColor(score, 'text')">{{ score }}</span> to
        <span class="text-atro-green">1000</span>
      </AtroHeading>

      <AtroContent class="w-full gap-8">
        <!-- Deploy Measures -->
        <AtroContent class="flex-1 gap-4" direction="col" items="center">
          <AtroIconWrap
            circle
            class="!p-3 shadow-md"
            name="lock"
            size="sm"
            type="light"
          />
          <AtroSpan class="text-atro-slate-purple">
            Deploy
            <AtroSpan
              semibold
              class="text-atro-orange"
              :text="missingSecurity.toString()"
            />
            missing security measures
          </AtroSpan>
        </AtroContent>

        <!-- Resolve Vulnerabilities -->
        <AtroContent class="flex-1 gap-4" direction="col" items="center">
          <AtroIconWrap
            circle
            class="!p-3 shadow-md"
            name="check"
            size="sm"
            type="light"
          />
          <AtroSpan class="text-atro-slate-purple">
            Resolve
            <AtroSpan
              semibold
              class="text-atro-orange"
              :text="vulnerabilities.toString()"
            />
            vulnerabilities
          </AtroSpan>
        </AtroContent>

        <!-- Monitor Threats -->
        <AtroContent class="flex-1 gap-4" direction="col" items="center">
          <AtroIconWrap
            circle
            class="!p-3 shadow-md"
            name="eye"
            size="sm"
            type="light"
          />
          <AtroSpan
            class="text-atro-slate-purple"
            text="Monitor for new threats"
          />
        </AtroContent>
      </AtroContent>
    </AtroCard>
  </AtroContent>
</template>

<script setup lang="ts">
import { getScoreColor } from '@atro/components'

export interface Props {
  score: number
  toolCoverage: number
  financialLossPotential: string
  missingSecurity: number
  vulnerabilities: number
  viewAnswersAction?: FlowAction
}

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

defineProps<Props>()
</script>
