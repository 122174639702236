<template>
  <AtroExpandableContent
    v-model="expanded"
    :class="[
      'banner-gradient__light w-full rounded-2xl',
      {
        grayscale: !isAiEnabled,
      },
    ]"
    direction="col"
    wrap="nowrap"
    id="atro-ai-insights"
  >
    <template #default="{ toggleExpand }">
      <AtroContent
        class="w-full cursor-pointer gap-10 p-4"
        items="center"
        justify="between"
        wrap="nowrap"
        @click="onExpand(toggleExpand)"
      >
        <AtroContent class="gap-4 text-atro-purple" items="center">
          <div class="relative flex">
            <AtroIcon
              class="absolute inline-flex size-6 opacity-0 group-hover:animate-ping group-hover:opacity-100"
              name="sparkles"
            />
            <AtroIcon
              class="relative inline-flex size-6"
              name="sparkles-outline"
            />
          </div>
          <AtroSpan semibold size="lg" text="Atro AI Insights" />
          <AtroBadge label="Preview" size="sm" />
        </AtroContent>

        <AtroContent class="gap-4">
          <AtroButton
            v-if="isAiEnabled"
            type="blank"
            @click.stop="openModal('aiConcent')"
          >
            <AtroIcon
              class="size-[18px] text-atro-purple-75"
              name="circle-info-outline"
            />
          </AtroButton>
          <!-- EXPAND TOGGLE -->
          <AtroExpandToggle
            :model-value="expanded"
            @keyup.space.prevent="() => null"
          />
        </AtroContent>
      </AtroContent>
    </template>

    <template #expanded-content>
      <div class="w-full p-4 pt-0">
        <AtroContent
          class="w-full gap-2 rounded-2xl bg-white p-6"
          direction="col"
          wrap="nowrap"
        >
          <div class="relative -mt-2 w-full pt-2">
            <div
              v-scroller
              ref="scrollerRef"
              class="max-h-[400px] w-full"
              @wheel="handleScroll"
            >
              <!-- Display conversation history -->
              <div v-if="messages.length > 0" class="w-full">
                <div
                  v-for="(message, index) in messages"
                  :key="index"
                  class="relative mb-3 rounded"
                  v-show="message.role !== 'user'"
                >
                  <AtroMarkdown
                    prose
                    class="text-sm"
                    :markdown="message.content"
                  />
                </div>
              </div>

              <!-- Current streaming response -->
              <div v-if="reading" class="mb-4 w-full">
                <div class="mb-1 text-xs text-gray-500">
                  <span class="animate-pulse">typing...</span>
                </div>
                <AtroMarkdown
                  prose
                  class="text-sm"
                  :markdown="currentResponse"
                />
              </div>
            </div>
          </div>

          <AtroContent
            v-auto-animate
            class="w-full gap-4"
            items="center"
            justify="center"
            direction="col"
          >
            <AtroContent
              v-if="initialResponceComplete && generatedPrompts.length"
              class="w-full gap-2"
            >
              <AiPromptCard
                v-for="generatedPrompt in generatedPrompts"
                :key="generatedPrompt.label"
                v-bind="generatedPrompt"
                @execute="sendPrompt"
              />
            </AtroContent>

            <Form
              v-if="showInput"
              v-model="formData"
              input-width="full"
              @submit="onSubmit"
            >
              <FormKit
                name="prompt"
                type="textarea"
                placeholder="What would you like to know?"
              />

              <template #submit="{ state: { valid } }">
                <AtroButton
                  text="Ask"
                  :disabled="!formData.prompt"
                  :pending="pending"
                />
              </template>
            </Form>
            <AtroButton
              v-if="initialResponceComplete && !showInput && false"
              text="Ask Follow Up"
              type="flat"
              size="sm"
              @click="showInput = true"
            />
            <AtroLoading v-if="!initialResponceComplete" />
          </AtroContent>
        </AtroContent>
      </div>
    </template>
  </AtroExpandableContent>
</template>

<script setup lang="ts">
import { domToDataUrl } from 'modern-screenshot'
import { useScroll } from '@vueuse/core'
import { dashboardFollowupPrompts } from '~/lib/ai/pagePrompts'

interface MessageDelta {
  type: 'content_block_delta'
  index: number
  delta: {
    type: 'text_delta'
    text: string
  }
}

interface Message {
  role: 'user' | 'assistant'
  content: string
}

interface GeneratedPrompt {
  value: string
  label: string
}

export interface Props {
  initialPrompt?: string
}

const GENERATE_PROMPTS_PROMPT = `Please use the attached image to generate three concise one sentence prompts that I could use to ask you about the data that would return interesting results. Please format your response in JSON in the following format with label being a few words descriptor of the prompt and then value being the entire prompt.
  [
    {
      value: '',
      label: ''
    }
  ]
`

const { initialPrompt = '' } = defineProps<Props>()

const router = useRouter()
const { openModal } = useModal()
const { uiContent, uiOrgState } = useUiConfig()

const currentResponse = ref('')
const hasResponse = ref(false)
const formData = ref({ prompt: initialPrompt })
const expanded = ref()
const image = ref()
const initialOpening = ref(true)
const generatingPrompts = ref(false)
const generatedPrompts = ref<GeneratedPrompt[]>(
  uiContent.value?.aiPrompts?.suggestions || dashboardFollowupPrompts,
)
const initialResponceComplete = ref(false)
const messages = ref<Message[]>([])
const reading = ref(false)
const scrollerRef = ref<HTMLElement>()
const showInput = ref()

const isAiEnabled = computed(() => uiOrgState.value.aiEnabled)

// Use VueUse's useScroll to handle scrolling
const { y } = useScroll(scrollerRef, {
  behavior: 'smooth',
})

function handleScroll(event: WheelEvent) {
  if (!scrollerRef.value) return

  const { scrollTop, scrollHeight, clientHeight } = scrollerRef.value
  const isScrollingUp = event.deltaY < 0
  const isScrollingDown = event.deltaY > 0

  // Prevent scroll propagation when:
  // 1. Scrolling up and already at the top
  // 2. Scrolling down and already at the bottom
  if (
    (isScrollingUp && scrollTop <= 0) ||
    (isScrollingDown && scrollTop + clientHeight >= scrollHeight)
  ) {
    event.preventDefault()
  }
}

// Scroll to bottom method using VueUse
function scrollToBottom() {
  if (scrollerRef.value) {
    y.value = scrollerRef.value.scrollHeight
  }
}

async function generatePrompts() {
  generatingPrompts.value = true
  const response = await sendRequest({
    image: image.value,
    prompt: GENERATE_PROMPTS_PROMPT,
  })
  const parsedResponse = JSON.parse(await readMessagesAsPromise(response))
  if (
    Array.isArray(parsedResponse) &&
    parsedResponse.every(
      (item): item is GeneratedPrompt =>
        item !== null &&
        typeof item === 'object' &&
        'value' in item &&
        'label' in item &&
        typeof item.value === 'string' &&
        typeof item.label === 'string',
    )
  ) {
    generatedPrompts.value = parsedResponse
  }
  generatingPrompts.value = false
}

function getScreenshotEl() {
  let el = document.querySelector('#__ai_screen_target')
  if (!el) {
    el = document.querySelector('#__nuxt_page')
  }
  return el
}

async function readMessages(response: ReadableStream) {
  reading.value = true
  // Create a new ReadableStream from the response with TextDecoderStream to get the data as text
  const reader = response.pipeThrough(new TextDecoderStream()).getReader()

  // Read the chunk of data as we get it
  try {
    while (true) {
      const { value, done } = await reader.read()

      if (done) {
        // Add assistant response to history
        messages.value.push({
          role: 'assistant',
          content: currentResponse.value,
        })
        reading.value = false
        hasResponse.value = true
        scrollToBottom() // Scroll to bottom when response is complete
        break
      }

      // Split the chunk by newlines in case we receive multiple events
      const lines = value.split('\n').filter((line) => line.trim())

      for (const line of lines) {
        try {
          const data = JSON.parse(line) as MessageDelta
          if (
            data.type === 'content_block_delta' &&
            data.delta.type === 'text_delta'
          ) {
            currentResponse.value += data.delta.text
            scrollToBottom() // Scroll to bottom as new text arrives
          }
        } catch (e) {
          console.error('Failed to parse message:', e)
        }
      }
    }
  } catch (error) {
    console.error('Error reading stream:', error)
    reading.value = false
  }
}

// New function: readMessagesAsPromise
async function readMessagesAsPromise(
  response: ReadableStream,
): Promise<string> {
  return new Promise((resolve, reject) => {
    let fullResponse = ''
    const reader = response.pipeThrough(new TextDecoderStream()).getReader()

    async function processStream() {
      try {
        while (true) {
          const { value, done } = await reader.read()

          if (done) {
            resolve(fullResponse)
            break
          }

          // Split the chunk by newlines in case we receive multiple events
          const lines = value.split('\n').filter((line) => line.trim())

          for (const line of lines) {
            try {
              const data = JSON.parse(line) as MessageDelta
              if (
                data.type === 'content_block_delta' &&
                data.delta.type === 'text_delta'
              ) {
                fullResponse += data.delta.text
              }
            } catch (e) {
              console.error('Failed to parse message:', e)
            }
          }
        }
      } catch (error) {
        console.error('Error reading stream:', error)
        reject(error)
      }
    }

    processStream()
  })
}

function sendRequest(data: { prompt: string; image?: string }) {
  scrollToBottom() // Scroll to bottom when new user message is added
  return $post<ReadableStream>(
    '/api/ai',
    {
      ...data,
      currentRouteName: router.currentRoute.value.name,
      messages: messages.value,
    },
    {
      responseType: 'stream',
    },
  )
}

async function sendPrompt(prompt: string) {
  // Reset current response for new request
  currentResponse.value = ''
  messages.value.push({
    role: 'user',
    content: prompt,
  })
  const response = await sendRequest({ image: image.value, prompt })
  await readMessages(response)
}

async function takeScreenshot() {
  const el = getScreenshotEl()
  if (el && import.meta.client) {
    const _image = await domToDataUrl(el)
    image.value = _image.replace('data:image/png;base64,', '')
  }
}

function onExpand(toggle: () => void) {
  if (isAiEnabled.value) toggle()
  else {
    openModal('aiConcent', {
      afterClose: () => {
        if (isAiEnabled.value) toggle()
      },
    })
  }
}

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(
  async (data) => {
    await sendPrompt(data.prompt)
    // Clear the form
    formData.value.prompt = ''
  },
)

watch(expanded, async (open) => {
  if (open && initialOpening.value) {
    initialOpening.value = false
    await takeScreenshot()
    // generatePrompts()
    await sendPrompt(initialPrompt)
    initialResponceComplete.value = true
  }
  await nextTick()
  scrollToBottom()
})

watch(isAiEnabled, () => {
  if (!isAiEnabled.value) expanded.value = false
})

// Expose the new readMessagesAsPromise function
defineExpose({
  readMessagesAsPromise,
})
</script>
