<template>
  <AtroContent
    class="editor w-full flex-1 sm:min-w-[480px]"
    direction="col"
    wrap="nowrap"
  >
    <AtroContent
      v-if="editor"
      class="sticky -top-0 z-10 w-full bg-white pt-4 sm:-top-12"
      direction="col"
      wrap="nowrap"
    >
      <AtroContent
        class="w-full border-b pb-4"
        justify="between"
        items="center"
        wrap="nowrap"
      >
        <AtroContent
          shrink
          class="w-full sm:space-x-2"
          items="center"
          wrap="nowrap"
        >
          <select
            v-model="format"
            class="rounded-[4px] border border-atro-warm-gray py-0 pl-2 pr-8"
          >
            <option value="paragraph">Paragraph</option>
            <option value="h1">H1</option>
            <option value="h2">H2</option>
            <option value="h3">H3</option>
          </select>

          <button
            :class="['editor-button', { 'is-active': editor.isActive('bold') }]"
            :disabled="!editor.can().chain().focus().toggleBold().run()"
            @click="editor?.chain().focus().toggleBold().run()"
          >
            <AtroIcon class="editor-icon" name="editor-bold" />
          </button>

          <button
            :class="[
              'editor-button',
              { 'is-active': editor.isActive('italic') },
            ]"
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
            @click="editor?.chain().focus().toggleItalic().run()"
          >
            <AtroIcon class="editor-icon" name="editor-italic" />
          </button>

          <!--  -->
          <button
            :class="[
              'editor-button',
              { 'is-active': editor.isActive('bulletList') },
            ]"
            :disabled="!editor.can().chain().focus().toggleBulletList().run()"
            @click="editor?.chain().focus().toggleBulletList().run()"
          >
            <AtroIcon class="editor-icon" name="editor-unordered-list" />
          </button>

          <button
            :class="[
              'editor-button',
              { 'is-active': editor.isActive('orderedList') },
            ]"
            :disabled="!editor.can().chain().focus().toggleOrderedList().run()"
            @click="editor?.chain().focus().toggleOrderedList().run()"
          >
            <AtroIcon class="editor-icon" name="editor-ordered-list" />
          </button>

          <button
            :class="[
              'editor-button',
              { 'is-active': editor.isActive('underline') },
            ]"
            @click="editor?.chain().focus().toggleUnderline().run()"
          >
            <AtroIcon class="editor-icon" name="editor-underline" />
          </button>

          <button
            :class="['editor-button']"
            @click="editor?.chain().focus().indent().run()"
          >
            <AtroIcon class="editor-icon" name="editor-indent" />
          </button>

          <button
            :class="['editor-button']"
            @click="editor?.chain().focus().outdent().run()"
          >
            <AtroIcon class="editor-icon" name="editor-outdent" />
          </button>
        </AtroContent>
        <AtroContent
          class="space-x-2 sm:space-x-4"
          items="center"
          wrap="nowrap"
        >
          <AtroContent class="sm:space-x-2" items="center" wrap="nowrap">
            <button
              class="editor-button"
              :disabled="!editor.can().chain().focus().undo().run()"
              @click="editor?.chain().focus().undo().run()"
            >
              <AtroIcon class="editor-icon" name="editor-undo" />
            </button>

            <button
              class="editor-button"
              :disabled="!editor.can().chain().focus().redo().run()"
              @click="editor?.chain().focus().redo().run()"
            >
              <AtroIcon class="editor-icon" name="editor-redo" />
            </button>
          </AtroContent>
          <AtroContent
            v-if="canReset"
            class="border-l-atro-warm-gray-1 sm:border-l sm:pl-4"
          >
            <AtroButton
              class="place-self-center sm:place-self-end"
              icon-left="rotate"
              type="flat"
              :text="$isMobile.value ? '' : resetActionLabel"
              @click="emit('reset')"
            />
          </AtroContent>
        </AtroContent>
      </AtroContent>
      <slot name="belowToolbar" />
    </AtroContent>
    <EditorContent
      class="editor-content prose flex w-full min-w-0 max-w-none flex-1 overflow-auto pt-6"
      :editor="editor"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import { Underline } from '@tiptap/extension-underline'
import { Table } from '@tiptap/extension-table'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { StarterKit } from '@tiptap/starter-kit'
import { Indent } from '@/lib/tiptap/IndentExtension'
import { SmartSpan } from '@/lib/tiptap/SmartSpanExtension'

export interface Props {
  modelValue: string

  autofocus?: boolean
  canReset?: boolean
  resetActionLabel?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  onEditor: [editor: Ref<Editor>]
  reset: []
  smartNodeClick: [key: string]
}>()
const modelValue = defineModel<Props['modelValue']>({ default: '' })

const editor = useEditor({
  content: modelValue.value,
  autofocus: props.autofocus,
  extensions: [
    Indent,
    SmartSpan,
    StarterKit,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Underline,
  ],
  editorProps: {
    handleClickOn: (view, pos, node) => {
      if (node.attrs['data-smart'])
        emit('smartNodeClick', node.attrs['data-smart'])
    },
  },
  onUpdate: () => {
    // HTML
    const newContent = editor.value?.getHTML()
    if (newContent) {
      modelValue.value = newContent
    }
  },
})
emit('onEditor', editor as Ref<Editor>)

const format = computed({
  get() {
    if (editor.value?.isActive('heading', { level: 1 })) {
      return 'h1'
    } else if (editor.value?.isActive('heading', { level: 2 })) {
      return 'h2'
    } else if (editor.value?.isActive('heading', { level: 3 })) {
      return 'h3'
    } else {
      return 'paragraph'
    }
  },
  set(value) {
    switch (value) {
      case 'paragraph':
        editor.value?.chain().focus().setParagraph().run()
        break
      case 'h1':
        editor.value?.chain().focus().toggleHeading({ level: 1 }).run()
        break
      case 'h2':
        editor.value?.chain().focus().toggleHeading({ level: 2 }).run()
        break
      case 'h3':
        editor.value?.chain().focus().toggleHeading({ level: 3 }).run()
        break
    }
  },
})

watch(
  modelValue,
  () => {
    try {
      if (editor.value?.getHTML() !== modelValue.value) {
        editor.value?.commands.setContent(modelValue.value || '', true)
      }
    } catch (e) {
      console.error('Error setting editor content', e)
    }
  },
  { immediate: true },
)
</script>

<style>
.editor-button {
  @apply inline-flex h-6 w-6 items-center justify-center rounded-[4px] transition-colors focus-visible:ring-2 focus-visible:ring-primary enabled:text-primary enabled:hover:bg-atro-cool-gray disabled:text-primary-50;
}

.editor-button.is-active {
  @apply bg-atro-cool-gray hover:bg-atro-cool-gray;
}

.editor-icon {
  @apply h-4 w-4;
}

.editor [contenteditable='true'] {
  @apply w-full;
}

.editor [contenteditable='true']:after {
  @apply block h-4 w-full content-[''];
}

.editor [data-smart] {
  @apply rounded-md bg-atro-cool-gray px-1 transition-colors;
}

.editor [data-smart][data-focused] {
  @apply bg-atro-yellow;
}
</style>
