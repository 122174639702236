export function useFrameworkLevels() {
  const { openModal } = useModal()
  const { getState, setUiState } = useUiConfig()
  const { isWalkthroughActive } = useOnboarding()
  const { isMember } = useCurrentRole()

  const availableFrameworkLevels = ref<FrameWorkLevel[]>([
    { label: 'Intro', value: 'intro' },
    {
      label: 'Essential',
      value: 'essential',
      modal: {
        headingText: 'Essentials',
        bodyText:
          "The Essentials level moves you from planning to action. In addition to your security policy, you'll implement key protections like staff training, phishing testing, and basic device management. This is the bare minimum needed to secure your business, making sure your team is aware of threats and your devices are protected.",
      },
    },
    {
      label: 'Basic',
      value: 'basic',
      modal: {
        headingText: 'Basic',
        bodyText:
          "At the Basic level, you're stepping up to a complete, well-rounded security setup. This includes a comprehensive set of security tools that build on your policy, training, phishing, and device protections. You'll cover all critical areas, ensuring your business is protected from common and emerging threats with strong, foundational security across the board.",
      },
    },
  ])

  const canLevelUp = computed(
    () =>
      currentFrameworkLevelIndex.value <
      availableFrameworkLevels.value.length - 1,
  )

  const currentFramework = computed(
    () => dashboardFrameworkRequest?.data.value as Framework,
  )
  const currentFrameworkProgress = computed(() =>
    Math.floor(
      (dashboardFrameworkRequest?.data.value?.props.progress || 0) * 100,
    ),
  )
  const currentFrameworkLevelIndex = computed(
    () => getState('org', 'currentFrameworkLevelIndex') || 0,
  )
  const currentFrameworlLevel = computed(
    () => availableFrameworkLevels.value[currentFrameworkLevelIndex.value],
  )

  const dashboardFrameworkRequest = useData<Framework>('dashboardFramework', {
    params: computed(() => ({
      slug: currentFrameworlLevel.value.value,
    })),
    options: {
      cached: true,
    },
  })

  function frameworkLevelUp() {
    const nextLevelIndex = currentFrameworkLevelIndex.value + 1
    const nextLevel = availableFrameworkLevels.value[nextLevelIndex]

    openModal('confirm', {
      props: {
        ...nextLevel.modal,
        confirmActionText: 'Enable',
        cancelable: false,
        iconName: 'shield-solid',
        onConfirm: () => {
          setUiState('org', 'currentFrameworkLevelIndex', nextLevelIndex)
          return true
        },
      },
    })
  }

  function jumpToLevel(slug: string) {
    const levelIndex = availableFrameworkLevels.value.findIndex(
      (level) => level.value === slug,
    )
    return setUiState('org', 'currentFrameworkLevelIndex', levelIndex)
  }

  // initialize framework level for existing users
  onMounted(async () => {
    if (
      !isMember.value &&
      !isWalkthroughActive.value &&
      currentFrameworkLevelIndex.value === 0
    ) {
      await jumpToLevel('basic')
      refreshNuxtData()
    }
  })

  return {
    availableFrameworkLevels,
    canLevelUp,
    currentFramework,
    currentFrameworkProgress,
    currentFrameworlLevel,
    frameworkLevelUp,
    jumpToLevel,
  }
}
