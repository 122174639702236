<template>
  <NuxtImg
    v-if="user?.imageUrl"
    class="rounded-full"
    referrerpolicy="no-referrer"
    :src="user?.imageUrl"
    :height="_size"
    :width="_size"
  />
  <Avatar v-else :name variant="beam" :colors="COLORS" :size="_size" />
</template>

<script setup lang="ts">
import Avatar from 'vue-boring-avatars'

export interface Props {
  user?: User
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const { user, size = 'md' } = defineProps<Props>()

const COLORS = ['#4F6BE9', '#51B2F0', '#7180F7', '#9541F3', '#DF529C']

const name = computed(() => user?.displayName || user?.email)

const _size = computed(() => {
  switch (size) {
    case 'sm':
      return 24
    case 'md':
      return 32
    case 'lg':
      return 48
    case 'xl':
      return 80
    default:
      return 32
  }
})
</script>
