<template>
  <AtroContent
    class="mx-auto w-full max-w-4xl gap-10 p-6"
    direction="col"
    wrap="nowrap"
  >
    <!-- Header Section -->
    <AtroContent
      class="w-full space-y-4 text-center"
      direction="col"
      items="center"
    >
      <AtroContent items="center" justify="center">
        <div class="bg-atro-purple-15 rounded-full p-4">
          <AtroIcon name="assessment-domain-security" />
        </div>
      </AtroContent>
      <AtroHeading
        semibold
        class="text-atro-slate-purple"
        size="lg"
        text="Domain Security"
      />
      <AtroContent
        :class="[
          'inline-flex items-center space-x-2 rounded-2xl border bg-white px-6 py-3',
          getRiskColor(riskLevel, 'border'),
        ]"
      >
        <AtroHeading semibold class="text-atro-gray"
          >{{ domain }} is at</AtroHeading
        >
        <AtroHeading
          semibold
          :class="getRiskColor(riskLevel, 'text')"
          :text="riskLevelText"
        />
      </AtroContent>
    </AtroContent>

    <!-- Security Status Grid -->
    <AtroContent class="gap-6" items="strech" wrap="nowrap">
      <!-- SPF Status -->
      <AtroContent direction="col" class="flex-1 space-y-2" items="center">
        <AtroContent direction="col" items="center" class="gap-3">
          <AtroIcon
            class="size-6"
            :class="status.spf ? 'text-atro-green' : 'text-atro-red'"
            :name="status.spf ? 'circle-check' : 'triangle-exclamation'"
          />
          <AtroHeading
            semibold
            size="xs"
            :class="status.spf ? 'text-atro-green' : 'text-atro-red'"
            :text="`SPF ${status.spf ? 'is configured' : 'is not setup'}`"
          />
        </AtroContent>
        <AtroParagraph class="text-center text-sm text-atro-gray">
          Your SPF record prevents email spoofing, protecting your domain's
          reputation and improving deliverability.
        </AtroParagraph>
      </AtroContent>

      <AtroDivider orientation="vertical" thickness="sm" />

      <!-- DKIM Status -->
      <AtroContent direction="col" class="flex-1 space-y-2" items="center">
        <AtroContent direction="col" items="center" class="gap-3">
          <AtroIcon
            class="size-6"
            :class="status.dkim ? 'text-atro-green' : 'text-atro-red'"
            :name="status.dkim ? 'circle-check' : 'triangle-exclamation'"
          />
          <AtroHeading
            semibold
            size="xs"
            :class="status.dkim ? 'text-atro-green' : 'text-atro-red'"
            :text="`DKIM ${status.dkim ? 'is configured' : 'is not setup'}`"
          />
        </AtroContent>
        <AtroParagraph class="text-center text-sm text-atro-gray">
          Updating your DKIM record secures your domain by verifying email
          authenticity, improving deliverability, and protecting against
          phishing attacks.
        </AtroParagraph>
      </AtroContent>

      <AtroDivider orientation="vertical" thickness="sm" />

      <!-- DMARC Status -->
      <AtroContent direction="col" class="flex-1 space-y-2" items="center">
        <AtroContent direction="col" items="center" class="gap-3">
          <AtroIcon
            class="size-6"
            :class="status.dmarc ? 'text-atro-green' : 'text-atro-red'"
            :name="status.dmarc ? 'circle-check' : 'triangle-exclamation'"
          />
          <AtroHeading
            semibold
            size="xs"
            :class="status.dmarc ? 'text-atro-green' : 'text-atro-red'"
            :text="`DMARC ${status.dmarc ? 'is configured' : 'is not setup'}`"
          />
        </AtroContent>
        <AtroParagraph class="text-center text-sm text-atro-gray">
          DMARC protects your domain from email spoofing by authenticating
          messages and providing reports on unauthorized attempts, giving you
          control over email security.
        </AtroParagraph>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { getRiskColor } from '@atro/components'

interface SecurityStatus {
  spf: boolean
  dkim: boolean
  dmarc: boolean
}

interface Props {
  domain: string
  riskLevel: 'low' | 'medium' | 'high'
  status: SecurityStatus
}

const props = defineProps<Props>()

const riskLevelText = computed(() => {
  return (
    props.riskLevel.charAt(0).toUpperCase() + props.riskLevel.slice(1) + ' Risk'
  )
})
</script>
