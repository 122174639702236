export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-to-copy', {
    mounted(el, binding) {
      const toast = useToast()
      el.addEventListener('click', () => {
        const textToCopy = binding.value

        if (textToCopy) {
          console.log('toast', toast)
          navigator.clipboard
            .writeText(textToCopy.toString())
            .then(() => {
              toast.success('Text copied')
            })
            .catch((err) => {
              toast.error('Failed to copy text')
            })
        }
      })
    },
  })
})
