<template>
  <FormKit
    dynamic
    type="list"
    v-model="context.value"
    :name="context.node.name"
    v-slot="{ items, node, value }"
  >
    <FormKit
      type="group"
      v-for="(item, index) in items"
      :key="item"
      :index="index"
    >
      <div
        :class="[
          'flex w-full flex-col gap-4',
          { 'rounded-3xl bg-atro-warm-gray-2 p-6': context.attrs.wrapped },
        ]"
      >
        <AtroContent
          class="w-full"
          justify="between"
          items="center"
          wrap="nowrap"
        >
          <!-- GROUP LABEL -->
          <AtroHeading
            semibold
            size="sm"
            :class="[
              '!w-auto text-atro-slate-purple',
              formkitTheme.global.outer,
            ]"
            :text="`${context.label} ${index + 1}`"
          />
          <!-- REMOVE BUTTON -->
          <AtroButton
            v-if="items.length > 1"
            type="flat"
            @click.stop.prevent="
              () => node.input(value.filter((_, i) => i !== index))
            "
          >
            <AtroIcon class="size-4" name="xmark" />
          </AtroButton>
        </AtroContent>
        <div
          :class="[
            'relative flex w-full',
            formkitTheme.global.outer,
            formkitTheme.global.gap,
            {
              'flex-col':
                context.attrs.direction === 'col' || !context.attrs.direction,
              'flex-row flex-wrap *:flex *:flex-1 *:flex-col *:!place-self-start':
                context.attrs.direction === 'row',
            },
          ]"
          :style="context.attrs.styleOverride"
        >
          <!-- GROUP SCHEMA -->
          <FormKitSchema :schema="repeatedSchema" />
        </div>
      </div>
    </FormKit>
    <AtroButton
      :icon-right
      class="place-self-start"
      type="flat"
      :disabled="!canAdd"
      :text="context?.attrs?.addLabel || 'Add Another'"
      v-bind="context?.attrs?.addButtonConfig"
      @click.stop.prevent="context.node.input(context.value.concat({}))"
    />
  </FormKit>
</template>

<script setup lang="ts">
import { theme as formkitTheme } from '@atro/formkit-config'
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const canAdd = computed(() =>
  context?.attrs?.maxItems
    ? context.value?.length < context?.attrs?.maxItems
    : true,
)
const iconRight = computed<IconName>(
  () => (context.addIcon as IconName) || 'plus',
)
const repeatedSchema = computed(() => {
  const _repeatedSchema = [...(context?.attrs?.repeatedSchema || [])]
  _repeatedSchema.forEach((item) => {
    if (item.formkit) {
      item.$formkit = item.formkit
      delete item.formkit
    }
  })
  return _repeatedSchema
})
</script>
