import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  async scrollBehavior(to, from, savedPosition) {
    const { scrollY } = useWindowScrollState()

    if (
      from &&
      to.name === 'orgs-slug' &&
      ((from.name as string) || '').includes('flows')
    ) {
      // wait for layout transition to finish before setting scroll
      await delay(350)
      if (savedPosition) return savedPosition
      else return { top: scrollY.value }
    } else if (from && ((to.name as string) || '').includes('flows')) {
      // wait for layout transition to finish before setting scroll
      await delay(350)
      return { top: 0 }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
}
