<template>
  <AtroModal size="lg">
    <template #default="{ close }">
      <AtroModalHeader
        icon="sparkles"
        icon-class="text-atro-purple"
        icon-size="sm"
        text="Atro AI Insights Overview"
        text-class="!text-transparent bg-primary-gradient !bg-clip-text"
        text-size="xl"
      >
        <template #below>
          <AtroContent items="center" justify="between" class="space-x-4">
            <AtroSpan
              semibold
              class="text-atro-gray-2"
              size="lg"
              text="AI Insights Status:"
            />
            <FormKit
              v-model="uiOrgState.aiEnabled"
              type="switch"
              name="aiInsightsEnabled"
              text-position="left"
              :_text-class="[
                'font-semibold',
                isAiEnabled ? 'text-atro-green' : 'text-atro-red',
              ]"
              :text="isAiEnabled ? 'Active' : 'Inactive'"
              @input="() => onToggle()"
            />
          </AtroContent>
        </template>
      </AtroModalHeader>

      <AtroModalContent class="space-y-8" align="left">
        <!-- What is section -->
        <AtroContent direction="col" class="space-y-4">
          <AtroHeading
            semibold
            size="xs"
            text="What is Atro AI Insights?"
            class="text-primary"
          />
          <AtroParagraph class="text-atro-gray">
            Atro's AI Insights provides instant clarity on your security,
            breaking down the status of each page into actionable insights. It
            highlights risks, identifies gaps, and recommends next steps, so you
            can focus your efforts where they matter most. Simplify
            decision-making and stay ahead of threats with a smarter, more
            intuitive way to manage security.
          </AtroParagraph>
        </AtroContent>

        <!-- How it works section -->
        <AtroContent direction="col" class="space-y-4">
          <AtroHeading
            semibold
            size="xs"
            text="How does it work?"
            class="text-primary"
          />
          <AtroParagraph class="text-atro-gray">
            Atro's AI Insights processes data only when the user expands the AI
            section on a page, ensuring control and privacy at every step. When
            enabled, the content is securely transmitted to a third-party REST
            API powered by a large language model (LLM), which analyzes the data
            and returns a summary of security risks, gaps, and actionable steps.
            If users prefer, they can completely disable the service, ensuring
            no data is sent externally unless explicitly requested.
          </AtroParagraph>
        </AtroContent>

        <!-- What information section -->
        <AtroContent direction="col" class="space-y-4">
          <AtroHeading
            semibold
            size="xs"
            text="What information is collected?"
            class="text-primary"
          />
          <AtroParagraph class="text-atro-gray">
            Atro's AI Insights only analyzes the content visible on the page
            when the AI section is expanded. This includes text, labels, and any
            other information directly presented on the screen. Atro does not
            connect to external services or access underlying systems—its
            assessment is based solely on what is displayed in the user
            interface. This ensures transparency and keeps the process simple,
            with no additional data being gathered beyond what the user already
            sees.
          </AtroParagraph>
        </AtroContent>
      </AtroModalContent>
    </template>

    <!-- Modal Actions -->
    <template #actions="{ close }">
      <AtroButton
        block
        :text="isAiEnabled ? 'Disable' : 'Enable'"
        @click="onToggle(close)"
      />
      <AtroButton block text="Close" type="light" @click="close" />
    </template>
  </AtroModal>
</template>

<script setup lang="ts">
export interface Props {}

defineProps<Props>()
const { uiOrgState, setUiState } = useUiConfig()

const isAiEnabled = computed(() => uiOrgState.value.aiEnabled)

function onToggle(close?: () => void) {
  setUiState('org', 'aiEnabled', !isAiEnabled.value)
  if (close) {
    close()
  }
}
</script>
