import { diff } from 'deep-object-diff'

export function usePageDirtyTracking(attachOnUnmount = true) {
  const originalRecipe = useState('dirtyOriginalRecipe')
  const pageIsDirty = useState('pageIsDirty', () => false)
  const dirtyDiff = useState<any>('dirtyDiff', () => ({}))
  const stopWatcher = ref<any>()

  function clearDirty() {
    originalRecipe.value = null
    pageIsDirty.value = false
    stopWatching()
  }

  function getDirtyDiff(source: any) {
    return diff(source, originalRecipe.value as {})
  }

  async function resetDirtyDiff(source: any) {
    if (source) {
      await wait(300)
      dirtyDiff.value = {}
      originalRecipe.value = { ...source }
      pageIsDirty.value = false
    }
  }

  async function setDirtySource(source: Ref<any>) {
    await resetDirtyDiff(source.value)
    watchSource(source)
  }

  function stopWatching() {
    if (stopWatcher.value) stopWatcher.value()
  }

  function watchSource(source: Ref<any>) {
    stopWatching()
    stopWatcher.value = watchDebounced(
      source,
      () => {
        if (source.value) {
          dirtyDiff.value = getDirtyDiff(unref(source))
          if (dirtyDiff.value)
            pageIsDirty.value = !!Object.keys(dirtyDiff.value).length
        }
      },
      { debounce: 100, deep: true },
    )
  }

  if (attachOnUnmount) onUnmounted(clearDirty)

  return { dirtyDiff, pageIsDirty, clearDirty, resetDirtyDiff, setDirtySource }
}
