import dot from 'dot-object'
import OnboardingOrgDataInput from '@/components/onboarding/OnboardingOrgDataInput.vue'
import OnboardingStepDataInput from '@/components/onboarding/OnboardingStepDataInput.vue'
import OnboardingUserDataInput from '@/components/onboarding/OnboardingUserDataInput.vue'

const userDataInputStep: OnboardingStepConfig = {
  component: OnboardingUserDataInput,
  type: 'userDataInput',
}

const orgDataInputStep: OnboardingStepConfig = {
  component: OnboardingOrgDataInput,
  type: 'orgDataInput',
}

export default function useOnboarding() {
  const router = useRouter()
  const { analytics } = useAnalytics()
  const { status, data: session } = useAuth()
  const { org, isKioskMode } = useCurrentOrg()
  const { user } = useCurrentUser()
  const { isMember } = useCurrentRole()
  const { missingOrg } = useUserOrgs()
  const { setUiState, uiContent, uiOrgState } = useUiConfig()

  const isAuthenticated = computed(() => status.value === 'authenticated')
  const isCreatingAnotherOrg = computed(
    () => router.currentRoute.value.path === '/create-org',
  )
  const onboardingContent = computed(() => uiContent.value?.onboardingV2)
  const onboardingState = computed(() => uiOrgState.value?.onboardingV2)
  const hasCompletedOnboardingSteps = computed(
    () => onboardingState.value?.hasCompletedOnboardingSteps,
  )
  const hasSeenLoading = computed(() => onboardingState.value?.loadingSeen)
  const isOnboarding = computed(
    () =>
      missingOrg.value ||
      (isMember.value && shouldCollectUserData.value) ||
      (!isMember.value &&
        org.value &&
        !isKioskMode.value &&
        !hasCompletedOnboardingSteps.value &&
        !isWalkthroughSkipped.value),
  )
  const isUserMissingRequiredData = computed(
    () => !user.value?.firstName || !user.value?.lastName,
  )
  const shouldConnectUser = computed(
    () => isAuthenticated.value && session.value?.claimToken,
  )
  const shouldCollectUserData = computed(
    () =>
      isAuthenticated.value &&
      (isUserMissingRequiredData.value || missingOrg.value),
  )

  // data collection step controller
  const initialDataCollectionSteps = ref<OnboardingStepConfig[]>([])
  if (isUserMissingRequiredData.value) {
    initialDataCollectionSteps.value.push(userDataInputStep)
  }
  if (missingOrg.value || isCreatingAnotherOrg.value) {
    initialDataCollectionSteps.value.push(orgDataInputStep)
  }

  const onboardingSteps = computed<OnboardingStepConfig[]>(() => {
    // modify data collection steps into OnboardingStepConfigs
    return [
      ...initialDataCollectionSteps.value,
      ...(isMember.value
        ? []
        : onboardingContent.value?.dataCollectionSteps.map((step, i) => {
            return {
              component: OnboardingStepDataInput,
              type: 'onboaringStep',
              props: {
                step,
                modelValue: [],
              },
            } as OnboardingStepConfig
          }) || []),
    ]
  })
  const stepIndex = ref(
    isCreatingAnotherOrg.value
      ? 0
      : Object.keys(onboardingState.value?.completedSteps || {}).length,
  )
  const stepsCount = computed(() => onboardingSteps.value.length)
  const currentStep = computed(() => onboardingSteps.value[stepIndex.value])
  const isFinalStep = computed(
    () => onboardingSteps.value.length - 1 === stepIndex.value,
  )
  function nextStep() {
    if (stepIndex.value + 1 < onboardingSteps.value.length) {
      stepIndex.value += 1
    }
  }
  // WALKTHROUGH
  const overrideWalkthroughSteps = useState<OnboardingWalkthroughStep[]>(
    'walkthroughStepsOverride',
  )
  const isWalkthroughActive = computed(
    () => walkthroughStatus.value === 'active',
  )
  const isWalkthroughSkipped = computed(
    () => walkthroughStatus.value === 'skipped',
  )
  const walkthroughStatus = computed(
    () => onboardingState.value?.walkthroughStatus,
  )
  const walkthroughSteps = computed(() =>
    overrideWalkthroughSteps.value?.length
      ? overrideWalkthroughSteps.value
      : onboardingContent.value?.walkthroughSteps
        ? onboardingContent.value?.walkthroughSteps.filter(
            (step) => step.pageSlug === router.currentRoute.value.name,
          )
        : [],
  )
  const walkthroughStepsSeen = computed(
    () => onboardingState.value?.walkthroughStepsSeen || [],
  )

  function skipWalkthrough() {
    const { jumpToLevel } = useFrameworkLevels()
    jumpToLevel('basic')
    return setOnboardingState('walkthroughStatus', 'skipped')
  }

  // MUTATIONS
  async function completeOnboarding() {
    await setOnboardingState('status', 'completed')
    analytics.value?.track('onboarding:completed')
  }
  async function seenOnboarding() {
    await setOnboardingState('seenOnboarding', true)
    analytics.value?.track('onboarding:seen')
  }
  async function skipOnboarding() {
    await setOnboardingState('status', 'skipped')
    analytics.value?.track('onboarding:skipped')
  }
  function getOnboardingState(key: string) {
    return dot.pick(key, onboardingState.value)
  }
  function setOnboardingState(key: string, value: any) {
    return setUiState('org', `onboardingV2.${key}`, value)
  }

  return {
    completeOnboarding,
    currentStep,
    getOnboardingState,
    hasCompletedOnboardingSteps,
    hasSeenLoading,
    isCreatingAnotherOrg,
    isFinalStep,
    isOnboarding,
    nextStep,
    onboardingState,
    onboardingSteps,
    seenOnboarding,
    setOnboardingState,
    skipOnboarding,
    shouldConnectUser,
    stepsCount,
    stepIndex,

    // WALKTHROUGH
    isWalkthroughActive,
    isWalkthroughSkipped,
    overrideWalkthroughSteps,
    skipWalkthrough,
    walkthroughStatus,
    walkthroughSteps,
    walkthroughStepsSeen,
  }
}
