<template>
  <AtroContent class="w-full gap-10" direction="col">
    <!-- Header -->
    <AtroContent
      class="w-full gap-3 text-center"
      direction="col"
      items="center"
    >
      <AtroIcon name="assessment-security-overview" />
      <AtroHeading
        semibold
        class="text-atro-slate-purple"
        size="lg"
        text="Security Alert Overview"
      />
      <AtroParagraph
        class="text-atro-gray"
        text="Alert breakdown based on missing security controls in your environment"
      />
    </AtroContent>

    <!-- Metrics Grid -->
    <AtroContent class="w-full" items="strech" justify="between">
      <!-- Total Alerts -->
      <AtroContent
        class="space-y-4"
        direction="col"
        items="center"
        justify="between"
      >
        <AtroHeading
          class="text-atro-slate-purple"
          size="sm"
          text="Total Alerts"
        />
        <AtroContent
          class="flex-1 gap-3 rounded-2xl border px-6 py-4"
          items="center"
          direction="col"
        >
          <div class="relative">
            <AtroContent
              class="absolute inset-0"
              items="center"
              justify="center"
              direction="col"
            >
              <AtroHeading
                semibold
                class="mt-4 !leading-none text-atro-slate-purple"
                size="xxl"
                :text="totalAlerts.toString()"
              />
              <AtroSpan
                semibold
                class="text-atro-gray"
                size="xs"
                text="Total"
              />
            </AtroContent>
            <ClientOnly>
              <VueApexCharts
                type="donut"
                height="150"
                width="150"
                :options="alertDistributionChartOptions"
                :series="alertDistributionSeries"
              />
            </ClientOnly>
          </div>
          <AtroContent class="h-14 w-full text-center" justify="around">
            <AtroContent direction="col" items="center">
              <AtroHeading
                semibold
                class="text-atro-red"
                :text="alertCounts.high.toString()"
              />
              <AtroSpan class="text-atro-gray" text="High" />
            </AtroContent>
            <AtroDivider orientation="vertical" size="md" thickness="sm" />
            <AtroContent direction="col" items="center">
              <AtroHeading
                semibold
                class="text-atro-orange"
                :text="alertCounts.medium.toString()"
              />
              <AtroSpan class="text-atro-gray" text="Medium" />
            </AtroContent>
            <AtroDivider orientation="vertical" size="md" thickness="sm" />
            <AtroContent direction="col" items="center">
              <AtroHeading
                semibold
                class="text-atro-blue"
                :text="alertCounts.low.toString()"
              />
              <AtroSpan class="text-atro-gray" text="Low" />
            </AtroContent>
          </AtroContent>
        </AtroContent>
      </AtroContent>

      <!-- Alert Distribution -->
      <AtroContent
        class="space-y-4"
        direction="col"
        items="center"
        wrap="nowrap"
      >
        <AtroHeading
          class="text-atro-slate-purple"
          size="sm"
          text="Alert Distribution"
        />
        <AtroContent
          class="flex-1 rounded-2xl border px-8 py-4"
          items="center"
          justify="center"
        >
          <DataDistribution
            :data="distributionData"
            x-label="LIKELIHOOD"
            y-label="IMPACT"
          />
        </AtroContent>
      </AtroContent>

      <!-- Alert Profile -->
      <AtroContent class="space-y-4" direction="col" items="center">
        <AtroHeading
          class="text-atro-slate-purple"
          size="sm"
          text="Alert Profile"
        />

        <AtroContent
          class="flex-1 rounded-2xl border p-3"
          items="center"
          justify="center"
        >
          <RiskProfileChart
            x-label="LIKELIHOOD"
            y-label="IMPACT"
            :bordered="false"
            :grid="false"
            :legend="false"
            :data="[
              {
                label: 'You',
                position: alertProfile.org,
                primary: true,
              },
              {
                label: 'Average',
                position: alertProfile.average,
              },
            ]"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>

    <!-- Top Alerts -->
    <AtroContent class="w-full gap-4" direction="col" items="center">
      <AtroHeading semibold class="text-atro-red" text="Top Alerts" />
      <AtroContent class="w-full gap-4" direction="col">
        <AtroContent
          v-for="alert in topAlerts"
          :key="alert.id"
          class="group w-full cursor-pointer rounded-full bg-white p-4 shadow transition-shadow hover:shadow-md"
          justify="between"
          items="center"
          @click="onAlertClick(alert)"
        >
          <AtroContent class="space-x-2" items="center">
            <AtroIcon
              circle
              class="size-6 text-atro-red"
              name="circle-exclamation"
              size="sm"
            />
            <AtroSpan
              semibold
              class="text-atro-red"
              size="lg"
              :text="alert.title"
            />
          </AtroContent>
          <AtroIcon class="size-5 text-atro-gray" name="chevron-right" />
        </AtroContent>
      </AtroContent>
    </AtroContent>

    <!-- View All Button -->
    <AtroContent class="w-full" justify="center">
      <AtroButton
        icon-right="chevron-right"
        text="View All Alerts"
        type="flat"
        @click="emit('viewAll')"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
interface AlertCounts {
  high: number
  medium: number
  low: number
}

interface AlertProfile {
  org: [number, number]
  average: [number, number]
}

interface Alert {
  id: string
  title: string
  severity: 'high' | 'medium' | 'low'
}

interface Props {
  alertCounts: AlertCounts
  alertProfile: AlertProfile
  distributionData: number[]
  topAlerts: Alert[]
}

const { alertCounts } = defineProps<Props>()
const emit = defineEmits<{
  viewAll: []
  alertClick: [alert: Alert]
}>()

const totalAlerts = computed(
  () => alertCounts.high + alertCounts.medium + alertCounts.low,
)

const alertDistributionSeries = computed(() => [
  alertCounts.high,
  alertCounts.medium,
  alertCounts.low,
])

const alertDistributionChartOptions = computed(() => ({
  labels: ['High', 'Medium', 'Low'],
  colors: ['#ED7C7C', '#FFB016', '#7180F7'],
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false, // Disable tooltips
  },
  stroke: {
    width: 5,
    lineCap: 'round',
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: false,
      donut: {
        size: '82%',
      },
      interactive: false, // Disable interactions
    },
  },
  chart: {
    events: {
      click: () => {}, // Disable click events
      mouseMove: () => {}, // Disable hover events
      mouseLeave: () => {}, // Disable mouse leave events
    },
  },
}))

function onAlertClick(alert: Alert) {
  emit('alertClick', alert)
}
</script>
