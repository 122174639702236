export const openPopup = ({
  url,
  title = '',
  w = 1024,
  h = 768,
  onPopupClosed,
}: {
  url: string
  title?: string
  w?: number
  h?: number
  onPopupClosed?: () => void
}) => {
  const left = screen.width / 2 - w / 2
  const top = screen.height / 2 - h / 2
  const popupWindow = window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left,
  )

  const timer = setInterval(function () {
    if (popupWindow?.closed) {
      clearInterval(timer)
      if (onPopupClosed) onPopupClosed()
    }
  }, 500)

  return popupWindow
}
