<template>
  <div ref="animationContainer"></div>
</template>

<script setup lang="ts">
import type { AnimationConfigWithData, AnimationItem } from 'lottie-web'
import lottie from 'lottie-web'

export interface Props {
  animationData: Record<string, unknown>

  autoPlay?: boolean
  loop?: boolean
  renderer?: 'svg'
}

const {
  animationData,
  loop = false,
  autoPlay = true,
  renderer = 'svg',
} = defineProps<Props>()

const emit = defineEmits<{
  complete: [anim: Ref<AnimationItem>]
  enterFrame: [anim: Ref<AnimationItem>]
  loopComplete: [anim: Ref<AnimationItem>]
}>()

const animationContainer = ref()
const animationRef = ref()

const init = () => {
  const settings: AnimationConfigWithData = {
    animationData,
    loop,
    renderer,
    autoplay: autoPlay,
    container: animationContainer.value as Element,
  }

  animationRef.value = lottie.loadAnimation(settings)

  animationRef.value.addEventListener('loopComplete', () => {
    emit('loopComplete', animationRef)
  })
  animationRef.value.addEventListener('complete', () => {
    emit('complete', animationRef)
  })
  animationRef.value.addEventListener('enterFrame', () => {
    emit('enterFrame', animationRef)
  })
}

onMounted(init)
onBeforeUnmount(() => {
  animationRef.value.destroy()
})
</script>
